import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import {useState} from "react";
import { useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import UserServices from '../services/UserServices'


// Copy-Write Function

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }



const theme = createTheme();

export const  AdminLogin =()=> {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate(); 

  const handleClick =(event)=>{
    // event.preventDefault()

//     UserServices.signIn(object)
//     .then(response =>{
//       // console.log(object);
//       console.log(response.data)
//       console.log("Login Successful");
//       const usrnam = response.data.username;
//       const psword = response.data.password;
//       if((usrnam === username) && (psword === response.data.password)){
//         navigate(`/8kns3/${response.data.user_ID}`)
//         // return (<HomePage />)
  
//       }else{
//         console.log("not successful")
//         alert("Invalid Credentials")
//       }
//       // navigate("/addRecord");


//   })
//   .catch(error =>{
//       console.log("Something went Wrong while updating" ,error);
//       alert("Invalid Credentials")
//   })
    if(username === "adminology"  && password ==="asd456"){
          navigate("/addAsAdmin/1");
    }else{
        alert("Invalid Credentials")
    }



  }

  
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            {/* Logo above login */}
            <LockOutlinedIcon  />
          </Avatar>

          <Typography component="h1" variant="h5">
            SIGN IN AS ADMIN
          </Typography>

          <Box component="form"
          //  onSubmit={handleSubmit}
            noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              // required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              // autoComplete="email"
              // autoFocus
              onChange={(e)=>setUsername(e.target.value)}
              value={username}
            />
            <TextField
              margin="normal"
              // required
              fullWidth
              // name="password"
              label="Password"
              type="password"
              // id="password"
              // autoComplete="current-password"
              onChange={(e)=>setPassword(e.target.value)}
              value={password}
      
            />
            {/* Check Box */}
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}

            <Button
              // type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={(event)=> handleClick(event)}
            >

              Sign In

            </Button>

            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}