import httpClient from "../http-comons";

//To get Data
const postContact =  creds  => {
    return httpClient.post("/contact",creds);
}

const getAll =  ()  => {
    return httpClient.get("/contact");
}

const getById =  (id)  => {
    return httpClient.get(`/contact/${id}`);
}

const deleteById = (id) =>{
    return httpClient.delete(`/contact/${id}`)
}

const update = (id,creds) =>{
    return httpClient.put(`/contact/${id}`,creds)
}





// // To Post Data
// const createNote =  note =>{
//     return httpClient.post("/notes",note)

// }

// // To get Data of One Note
// const getById = (id) =>{
//     return httpClient.get(`/notes/${id}`)
// }

// const deleteById = (id) =>{
//     return httpClient.delete(`/notes/${id}`)
// }




// Warning is showed if we dont declare export in an object |
// This is a correct wat to save all the methods in
const exportedObject = {
    // signIn,
    postContact,
    getAll,
    deleteById,
    update,
    getById
    // signUp,
    // getAllContacts,
};
// 

export default exportedObject;