
import './App.css';
import SignIn from './pages/loginPage';
import { Route, Routes } from 'react-router';
import { BrowserRouter,   } from 'react-router-dom';
import { PageNotFound } from './pages/PageNotFound';
import SignUp from './pages/SignUpPage';

import { ShowRecords } from './components/ShowRecords';
import { CsvUpload } from './components/csvUpload';
import { AllRecords } from './pages/AllRecordsPage';
import { DefaultPage } from './pages/DefaultPage';
import { AdminLogin } from './adminPages/AdminLogin';
import { AdAllRecords } from './adminPages/AdAllRecords';
import { AdAddRec } from './adminPages/AdAddRec';
import { Tabley } from './adminPages/Table';
// import ExportDefaultToolbar from './adminPages/LastComponent';
import  {UploadFile}  from '../src/components/UploadFile';
import { FileWorks } from './extraComponents/FileWorks';
import { SimpleUTable } from './extraComponents/SimpleUTable';
// import {Dashboarda}  from './components/Dashboard'
import { DAASH } from './components/DAASH';
import { EditRecord } from './components/EditRecord';
// import UsersTable from './adminPages/ghf';

function App() {
  return (
    <div className="App">
       <BrowserRouter>
            {/* <Topbar /> */}
      <Routes>
        <Route path='/' element={<DefaultPage />} />
        <Route path='/8kns3/:id' element={<DAASH />} />
        {/* <Route path='/about' element={<AboutPage />} /> */}
        <Route path="/records/:id" element={<ShowRecords/>}></Route>
        <Route path="/lf78s0/:id" element={<AllRecords/>}></Route>

        <Route path='/67sgbs97/:id' element={<SimpleUTable />} />
        <Route path="/basay/:id" element={<FileWorks />} />

        <Route path='/67sgbs97' element={<Tabley />} />
        <Route path='/basayyyyy/:id' element={<UploadFile />} />
        <Route path="/login" element={<SignIn/>}></Route>
        <Route path="/regabc" element={<SignUp/>}></Route>
        <Route path="/csvUpload" element={<CsvUpload/>}></Route>

        <Route path='*' element={<PageNotFound />} />

        <Route path="/adminology" element={<AdminLogin/>}></Route>
        <Route path="/addAsAdmin/:id" element={<AdAddRec/>}></Route>
        <Route path="/AdminAllRecs/:id" element={<AdAllRecords/>}></Route>
        <Route path="/90id3j3yu4hu4hugygy3gg3ygf4yg4y4uy4uh8editRecord/:id" element={<EditRecord/>}></Route>


        {/* <Route path='/last' element={<ExportDefaultToolbar />} /> */}
        {/* <Route path='/ut' element={<UsersTable />} /> */}


      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
