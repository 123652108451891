import httpClient from "../http-comons";

//To get Data
const signIn =  creds  => {
    return httpClient.post("/user/login",creds);
}

const signUp =  creds  => {
    return httpClient.post("/user/register",creds);
}

const getAllContacts =()=> {
    return httpClient.get("/contact");
}

// // To Post Data
// const createNote =  note =>{
//     return httpClient.post("/notes",note)

// }

// // To get Data of One Note
// const getById = (id) =>{
//     return httpClient.get(`/notes/${id}`)
// }

// const deleteById = (id) =>{
//     return httpClient.delete(`/notes/${id}`)
// }

// const update = (data) =>{
//     return httpClient.put("/notes",data)
// }



// Warning is showed if we dont declare export in an object |
// This is a correct wat to save all the methods in
const exportedObject = {
    signIn,
    signUp,
    getAllContacts,
};
// 

export default exportedObject;