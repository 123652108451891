import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useState} from "react";
import { useParams } from "react-router-dom";
import ContactServices from '../services/ContactServices'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


// import Chart from './Chart';
// import Deposits from './Deposits';

import {
  // Avatar,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
// import { AppBarrr } from "./AppBarrr";
import { AdminBarrr } from "./AdminBarrr";

// function Copyright(props) {
//   return (
//     <Typography
//       variant="body2"
//       color="text.secondary"
//       align="center"
//       {...props}
//     >
//       {"Copyright © "}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{" "}
//       {new Date().getFullYear()}
//       {"."}
//     </Typography>
//   );
// }





// const Drawer = styled(MuiDrawer, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   "& .MuiDrawer-paper": {
//     position: "relative",
//     whiteSpace: "nowrap",
//     width: drawerWidth,
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     boxSizing: "border-box",
//     ...(!open && {
//       overflowX: "hidden",
//       transition: theme.transitions.create("width", {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//       }),
//       width: theme.spacing(7),
//       [theme.breakpoints.up("sm")]: {
//         width: theme.spacing(9),
//       },
//     }),
//   },
// }));

const mdTheme = createTheme();

export const AdAddRec =()=> {

  // For Errors

  const params = useParams();

  // const [user,setUser] = useState('');
  const [first_name,setfirst_name] = useState('');
  const [last_name,setlast_name] = useState('');
  const [company_name,setcompany_name] = useState('');
  const [street,setstreet] = useState('');
  const [post_code,setpost_code] = useState('');
  const [state,setstate] = useState('');
  const [email,setemail] = useState('');
  const [phoneno,setphoneno] = useState('');
  const [source,setsource] = useState('');
  const [category,setcategory] = useState('');
  const [plateform,setplateform] = useState('');
  const [comment,setcomment] = useState('');
  const [action,setaction] = useState('');

  const [upload, setUpload] = useState(false)

  const handleClick =(event)=>{
    // event.preventDefault()
     const entered_by =(`${params.id}`)
    const object = {first_name,last_name,company_name,street,post_code,state,email,phoneno,source,category,plateform,comment,action,entered_by}
    if(!email.includes('@')){
      alert("Invalid Email / Phone Number")
      return;
    }
    ContactServices.postContact(object)
    // console.log(object);
    .then(response =>{
      // console.log(object);
      // console.log(response.data)
      console.log("Posted Successfully");
      
      // navigate(`/addRecord/${params.id}`); 

          setfirst_name('');
          setlast_name('');
          setcompany_name('');
          setstreet('');
          setpost_code('');
          setstate('');
          setemail('');
          setphoneno('');
          setsource('');
          setcategory('');
          setplateform('');
          setcomment('');
          setaction('');

          setUpload(true);


  })
  .catch(error =>{
      // console.log("Something went Wrong while updating" ,error);
      alert("Invalid Record Entered \nPossible Issues\nEmail/Number Already Exists\nRequired Fields are not filled ")
  })



  }


// restrict to enter only phone number
  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setphoneno(value);
  };

  const onChangeEmail = (e) =>{
    setemail(e.target.value)

  }



  return (
<>

    <AdminBarrr />
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        {/* <Drawer variant="permanent" open={open}> */}
          {/* <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar> */}
          {/* <Divider /> */}
          {/* Side Bar */}
          {/* <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List> */}
        {/* </Drawer> */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    // height: 240,
                  }}
                  >
                  {/* <Typography variant="inherit">Enter Record!</Typography> */}

                  <Grid container direction="row" spacing={8}>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sm={4}
                      style={{ maxWidth: "400px" }}
                    >
                      <Stack direction="column">
                        <TextField
                          id="standard-basic"
                          label="First name"
                          variant="standard"
                          value={first_name}
                          onChange={(e)=>setfirst_name(e.target.value)}
                        />
                        <TextField
                          id="standard-basic"
                          label="Last name"
                          variant="standard"
                          value={last_name}
                          onChange={(e)=>setlast_name(e.target.value)}
                          />
                        <TextField
                          id="standard-basic"
                          label="Company name"
                          variant="standard"
                          value={company_name}
                          onChange={(e)=>setcompany_name(e.target.value)}
                        />
                        <TextField
                          id="standard-basic"
                          label="Street"
                          variant="standard"
                          value={street}
                          onChange={(e)=>setstreet(e.target.value)}
                        />
                        <TextField
                          id="standard-basic"
                          label="State"
                          variant="standard"
                          value={state}
                          onChange={(e)=>setstate(e.target.value)}
                        />
                      </Stack>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={4}
                      sm={4}
                      style={{ maxWidth: "400px" }}
                      >
                      <Stack direction="column">
                        <TextField
                          id="standard-basic"
                          label="Post Code"
                          variant="standard"
                          value={post_code}
                          onChange={(e)=>setpost_code(e.target.value)}
                        />
                        <TextField
                          id="standard-basic"
                          label="E-mail"
                          variant="standard"
                          type="email"
                          required
                          value={email}
                          onChange={onChangeEmail}
                        />
                        <TextField
                          id="standard-basic"
                          label="Phone number"
                          variant="standard"
                          required
                          // type="number"
                      
                          value={phoneno}
                          // onChange={(e)=>setphoneno(e.target.value)}
                          onChange={handleChange}
                        />

                        <TextField
                          id="standard-basic"
                          label="Source"
                          variant="standard"
                          value={source}
                          onChange={(e)=>setsource(e.target.value)}
                        />
                      </Stack>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={4}
                      sm={4}
                      style={{ maxWidth: "400px" }}
                    >
                      <Stack direction="column">
                        <FormControl
                          variant="filled"
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={category}
                            onChange={(e)=>setcategory(e.target.value)}
                            // Customer
                            // Professional
                            // Seller
                            // Host
                            // Vehicle Owner
                            // Equipment Owner
                            // Equipment buyer
                            // General
                            // value={age}
                            // onChange={handleChange}
                            >
                            <MenuItem >
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={'Customer'} >Customer</MenuItem>
                            <MenuItem value={'Professional'} >Professional</MenuItem>
                            <MenuItem value={'Seller'} >Seller</MenuItem>
                            <MenuItem value={'Host'} >Host</MenuItem>
                            <MenuItem value={'Vehicle Owner'} >Vehicle Owner</MenuItem>
                            <MenuItem value={'Equipment Owner'} >Equipment Owner</MenuItem>
                            <MenuItem value={'Equipment buyer'} >Equipment buyer</MenuItem>
                            <MenuItem value={'General'} >General</MenuItem>
                          </Select>
                        </FormControl>

                        <FormControl
                          variant="filled"
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                          Platform
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={plateform}
                            onChange={(e)=>setplateform(e.target.value)}
                            // value={age}
                            // onChange={handleChange}
                          >
                            <MenuItem value="Market Place">
                              <em>Market Place</em>
                            </MenuItem>
                            <MenuItem 
                            value="Contractor"
                            >Contractor</MenuItem>
                            <MenuItem 
                            value="Neutral"
                            >Neutral</MenuItem>
                          </Select>
                        </FormControl>

                        
                        
                        <FormControl
                          variant="filled"
                          sx={{ m: 1, minWidth: 120 }}
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            Action
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={action}
                            onChange={(e)=>setaction(e.target.value)}
                          >
                            <MenuItem value="Whatsapp">
                              <em>Whatsapp</em>
                            </MenuItem>
                            <MenuItem 
                            value="Emailed"
                            >Emailed</MenuItem>
                           <MenuItem value="Called">Called</MenuItem>
                           <MenuItem value="Visited">Visited</MenuItem>
                           <MenuItem value="Onboard">Onboard</MenuItem>
                          </Select>
                        </FormControl>
                       

                        <TextField
                          id="standard-basic"
                          label="Comment"
                          variant="standard"
                          value={comment}
                          onChange={(e)=>setcomment(e.target.value)}
                        />

               
                      </Stack>
                    </Grid>

                    {/* <Grid item xs={6} md={6} >
              <TextField id="standard-basic" label="Standard" variant="standard" />
              <TextField id="standard-basic" label="Standard" variant="standard" />
              <TextField id="standard-basic" label="Standard" variant="standard" />
                </Grid>

                <Grid item xs={6} md={6} >
              <TextField id="standard-basic" label="Standard" variant="standard" />
              <TextField id="standard-basic" label="Standard" variant="standard" />
              <TextField id="standard-basic" label="Standard" variant="standard" />
                </Grid> */}
                  </Grid>
                  <Button
                          sx={{ mt: "10px" }}
                          color="primary"
                          variant="contained"
                          style={{width:"200px", alignSelf:"center"}}
                          onClick={(event)=> handleClick(event)}

                        >
                          SAVE RECORD
                        </Button>

                  {/* <Chart /> */}
                </Paper>
              </Grid>

              {/* Recent Deposits */}
              <Grid item xs={12} md={4} lg={3}>
                {/* <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <Deposits />
                  </Paper>
 */}
              </Grid>
              {/* Recent Orders */}
 
            </Grid>
            {/* <Copyright sx={{ pt: 4 }} /> */}
          </Container>
  
                {
                  upload &&
                  <div>
                      <h1>RECORED ENTERED SUCCESSFULLY</h1> <CheckCircleIcon sx={{color:"green"}} />
                  </div>
              }
        
        </Box>
      </Box>
    </ThemeProvider>
</>
  );
}


