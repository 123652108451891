import { Button, Paper, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AppBarrr } from '../components/AppBarrr'
import { useAuth0 } from "@auth0/auth0-react";

export const  DefaultPage= () => {

    const { loginWithRedirect } = useAuth0();

  
  return (
    <>
    {/* <AppBarrr /> */}
   <Box margin={8} padding={3}>
    <Paper style={{padding:"29px"}}>
        <Stack spacing={4} style={{marginTop:"16px"}}>
        <Typography variant='h2' style={{color:"black", fontWeight:"bolder"}}>WELCOME TO cms.darntl.com</Typography>
        {/* <Typography>Login <Link to="/allRecords">here</Link></Typography> */}
        {/* <Button style={{width:"200px", alignSelf:"center"}} variant='outlined' onClick={() => loginWithRedirect()}>Get Started</Button> */}
        <Button style={{width:"200px", alignSelf:"center"}} variant='outlined' color='info' ><Link to="/login" style={{textDecoration:"none", color:"black", fontWeight:"bold"}}>Log In</Link></Button>
        <Button style={{width:"200px", alignSelf:"center"}} variant='outlined' color='info' ><Link to="/adminology" style={{textDecoration:"none", color:"black", fontWeight:"bold"}}>Log In As Admin</Link></Button>
        {/* <Button style={{width:"200px", alignSelf:"center"}} variant='outlined' color='warning' onClick={() => loginWithRedirect()}>Sign Up</Button> */}
        {/* <Typography>Add New Record,  <Link to="/addRecord">Add new</Link></Typography>
        <Typography>View All Records <Link to="/allRecords">here</Link></Typography> */}
        </Stack>
    </Paper>
   </Box>
    </>
  )
}
