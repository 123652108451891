import { AppBar, IconButton, Toolbar,  Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Link } from "react-router-dom"
import Tooltip from '@mui/material/Tooltip'
import DensitySmallSharpIcon from '@mui/icons-material/DensitySmallSharp';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import AdjustIcon from '@mui/icons-material/Adjust';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useParams } from "react-router-dom";

// import { useAuth0 } from "@auth0/auth0-react";
// import { PhotoCamera } from "@mui/icons-material";
// import Profile from "./Profile";


// function Profile() {
//   const { user, isAuthenticated, isLoading } = useAuth0();

//   if (isLoading) {
//     return <div>Loading ...</div>;
//   }

//   return (
//     isAuthenticated && (
//       <Box >
//         {/* <Image src={user.picture} alt={user.name} /> */}
//         {/* <Typography variant="body1">{user.name}</Typography> */}
//         <Typography variant="body1">{user.email}</Typography>
//       </Box>
//     )
//   );
// };

export const  AdminBarrr=()=> {
  // const { logout } = useAuth0();
  const params = useParams();



  return (
    <AppBar position="absolute" >
    <Toolbar
      sx={{
        pr: "24px", // keep right padding when drawer closed
      }}
    >
      {/* <IconButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        sx={{
          marginRight: "36px",
          ...(open && { display: "none" }),
        }}
      >
        <MenuIcon />
      </IconButton> */}
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        align="left"
        sx={{ flexGrow: 1 }}
      >
        {/* <Link to="/" style={{color:"white"}}> */}
        ADMIN PANNEL
      {/* </Link> */}
      </Typography>           
      
 <Box alignSelf="center">

      <Tooltip title="View all Records">
        <IconButton>
          <Link to={"/AdminAllRecs/1"}>
        <DensitySmallSharpIcon sx={{color:"white"}}  />
          </Link>
        </IconButton>
        </Tooltip>
        
        <Tooltip title="Add new Record">
         <IconButton>
          <Link to = {`/addAsAdmin/1`} >
        <AddCircleSharpIcon sx={{color:"white"}} />
          </Link>
        </IconButton>
        </Tooltip>

        <Tooltip title="Advanced Options">
         <IconButton>
          <Link to = {`/67sgbs97`} >
        <AdjustIcon sx={{color:"white"}} />
          </Link>
        </IconButton>
        </Tooltip>

        <Tooltip title="File Options">
<Link to={`/basay/${params.id}`}>
        <IconButton variant="contained" component="label">
        <InsertDriveFileIcon sx={{color:"white"}} />

        </IconButton>
</Link>
      </ Tooltip>




        

        
 </Box>
        
          <Box>
            {/* <Profile /> */}
          </Box>


    </Toolbar>
  </AppBar>
  )
}
