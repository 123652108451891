import { Box, Stack } from '@mui/system'
import React from 'react'
// import axios from 'axios';
import {useState} from "react"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { AdminBarrr } from '../adminPages/AdminBarrr';
import { AppBarrr } from '../components/AppBarrr';
import { Typography } from '@mui/material';


    export const UploadFile = ()=> {

        const [upload, setUpload] = useState(false)

    const handleFileUpload = (event) => {
            event.preventDefault();
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            fetch('https://cms-java-backend.herokuapp.com/contact/import', {
            method: 'POST',
            body: formData
            }).then(response => {
                setUpload(true)
            console.log(response);
            }
            
            ).catch(err =>{
                alert("Invalid File Format\n Must Enter Valid CSV Format\nYou can check the format by downloading it from link below")
            })
        }

        return (
            <>
            <AppBarrr />
            
        <Box sx={{alignSelf :"center", backgroundColor:"#242F40"}}>


        <Stack direction="column"  >

        <Box m={5} p={6} mt={10} sx={{backgroundColor:"#1976d2", color:"white", border:"2px solid", borderRadius:"100px"}} pt={7}>
            <form>
                {/* You can check format by downloading the format below */}
                <Typography variant='h4'>FILE UPLOAD</Typography>
                <p  style={{fontWeight:"bolder"}} >Upload Compatible version of csv  </p>
                <em style={{fontWeight:"bolder"}} >After file upload wait for response</em>
                <p  style={{fontWeight:"bolder"}} >Select Your File </p>
            <input style={{backgroundColor:"white" , border:"4px solid white", color:"black" }} type="file" onChange={handleFileUpload} />
        </form>
        </Box>

    {
        upload &&
        <div>
            <h1 style={{color:"white"}}>File Uploaded</h1> <CheckCircleIcon sx={{color:"green"}} />
        </div>
    }

    <Box m={5} p={6} mt={4} sx={{backgroundColor:"#1976d2", color:"white", border:"2px solid", borderRadius:"100px"}} >
    <Typography variant='h4'>FILE DOWNLOAD</Typography>
    <div style={{marginTop:"40px"}}>
        <p>In order to download all the records in the database click the link </p>
        <a href="https://cms-java-backend.herokuapp.com/contact/export" style={{color:"#F7F6C5"}}>Download CSV File of All records</a>
    </div>
    
    </Box>

    </Stack>
        </Box>
        </>
        )
    }



