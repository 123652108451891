
import { AdminBarrr } from "../adminPages/AdminBarrr"

import axios from "axios"
import { useState } from "react"
import { Box } from "@mui/system";

export const  CsvUpload=()=> {

    // const [file, setFile] = useState();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSubmit = async(event) => {
      event.preventDefault()
      const formData = new FormData();
      formData.append("selectedFile", selectedFile);
      try {
        const response = await axios({
          method: "post",
          url: "http://localhost:8080/contact/import",
          data: formData,
          // headers: { "Content-Type": "multipart/form-data" },
        });

      } catch(error) {
        console.log(error)
      }
    }


    
    const handleFileSelect = (event) => {
      setSelectedFile(event.target.files[0])
    }

  return (
    <>

    <AdminBarrr />


    <div p={7} style={{marginTop:"88px"}}>
      CSV COMPONENT


      <div>
      {/* <Button variant="contained" component="label">
          Upload
          <input hidden accept=".csv/*" multiple type="file" />
        </Button> */}

<div>

<form onSubmit={handleSubmit}>
      <input type="file" onChange={handleFileSelect}/>
      <input type="submit" value="Upload File" />
    </form>

    </div>

    


        <Box p={3} m={3} >
                <a href="http://localhost:8080/contact/export">
                Download CSV File
                </a>
        </Box>
      </div>
    </div>
    </>
  )
}
