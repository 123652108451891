import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useState, useEffect} from "react";
import ContactServices from '../services/ContactServices';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Grid, IconButton } from '@mui/material';
import { Link, useNavigate, useParams} from 'react-router-dom';
import { AdminBarrr } from './AdminBarrr';
import { Box } from '@mui/system';
import EditIcon from '@mui/icons-material/Edit';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export const AdAllRecords=()=> {

  const params  = useParams();
  let navigate = useNavigate(); 

  const handleDelete = (id) =>{
    // console.log(id);
    // id = state.id
    ContactServices.deleteById(id)
    .then(response =>{
      console.log("Deleted Successfully",response);
      navigate(`/AdminAllRecs/${params.id}`);
    })
    .catch((err)=>{
      console.log("Something went Wrong",err);
    })
  }


  // const handleEdit = (id) =>{
  //   ContactServices.deleteById(`/${state.id}`)
  //   .then(response =>{
  //     console.log("Deleted Successfully",response);
  //     navigate("/AdminAllRecs");
  //   })
  //   .catch((err)=>{
  //     console.log("Something went Wrong",err);
  //   })
  // }

  // const handleEdit = () =>{
  //   navigate(`/AdminAllRecs/${params.id}`)
  // }


  const [state,setState] = useState([])

  useEffect(()=>{
  
    ContactServices.getAll()
      .then(res =>{
        console.log(res.data);
        setState(res.data)
      },[])
      .catch(error=>{
        console.log("Something Went Wrong", error);
      },[]) 

    // Add square bracket here befre production
  })
  return (
    <>
    <AdminBarrr />
    <Paper elevation={10} style={{ marginTop:"100px"}}>

      <Box >
      <Button style={{color:"black"}}>Total Records: {state.length}</Button>
      </Box>
      <Grid container >
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} 
      aria-label="customized table">
        <TableHead >
          <TableRow>
            <StyledTableCell>First name</StyledTableCell>
            <StyledTableCell >Last Name</StyledTableCell>
            <StyledTableCell >Company</StyledTableCell>
            <StyledTableCell >Street</StyledTableCell>
            <StyledTableCell >Postal Code</StyledTableCell>
            <StyledTableCell >State</StyledTableCell>
            <StyledTableCell >Email</StyledTableCell>
            <StyledTableCell >Phone Number</StyledTableCell>
            <StyledTableCell >Source</StyledTableCell>
            <StyledTableCell >Category</StyledTableCell>
            <StyledTableCell >Platform</StyledTableCell>
            <StyledTableCell >Action</StyledTableCell>
            <StyledTableCell >Comment</StyledTableCell>
            <StyledTableCell >Delete</StyledTableCell>
            <StyledTableCell >Edit</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.map(row=> (
            <StyledTableRow key={row.contact_ID}>
              <StyledTableCell component="th" scope="row">
                {row.first_name}
              </StyledTableCell>

              <StyledTableCell component="th" scope="row">
                {row.last_name}
              </StyledTableCell>

              <StyledTableCell  style={{fontSize:"10px"}} >{row.company_name}</StyledTableCell>
              <StyledTableCell  style={{fontSize:"12px"}} >{row.street}</StyledTableCell>
              <StyledTableCell  style={{fontSize:"12px"}} >{row.post_code}</StyledTableCell>
              <StyledTableCell  style={{fontSize:"12px"}} >{row.state}</StyledTableCell>
              <StyledTableCell  style={{fontSize:"12px"}}>{row.email}</StyledTableCell>
              <StyledTableCell  style={{fontSize:"12px"}} >{row.phoneno}</StyledTableCell>
              <StyledTableCell  style={{fontSize:"12px"}} >{row.source}</StyledTableCell>
              <StyledTableCell  style={{fontSize:"12px"}} >{row.category}</StyledTableCell>
              <StyledTableCell  style={{fontSize:"12px"}} >{row.plateform}</StyledTableCell>
              <StyledTableCell  style={{fontSize:"12px"}} >{row.action}</StyledTableCell>
              <StyledTableCell  style={{fontSize:"12px"}} >{row.comment}</StyledTableCell>
              {/* <StyledTableCell >
                <IconButton
                onClick={()=>handleEdit(row.contact_ID)}
                 >
                <EditIcon />
                </IconButton>
                </StyledTableCell> */}

                <StyledTableCell >
                <IconButton onClick={()=>handleDelete(row.contact_ID)}>
                <DeleteIcon />
                </IconButton>
                </StyledTableCell>

                <StyledTableCell >
                  <Link to={`/90id3j3yu4hu4hugygy3gg3ygf4yg4y4uy4uh8editRecord/${row.contact_ID}`}>
                <IconButton >
                <EditIcon />
                </IconButton>
                  </Link>
                </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    </Grid>
    

     {/* {state.map((data)=>(
 
        <div key={data.contact_ID}>
        <p>{data.email}</p>
      </div>
     
     ) )} */}
          
    </Paper>
    </>
  );
}