import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid,GridToolbar,  } from "@mui/x-data-grid";
import {useState, useEffect} from "react";
import ContactServices from '../services/ContactServices';

// import { AdminBarrr } from "./AdminBarrr";
import { AppBarrr}  from "../components/AppBarrr";
// import DeleteIcon from '@mui/icons-material/Delete';



export const SimpleUTable = () => {
    
    const columns = [
      {
        field: "first_name",
        headerName: "FIRST_NAME",
        width: 100,
        editable: false,
      },
      {
        field: "last_name",
        headerName: "LAST_NAME",
        width: 100,
        editable: false,
      },
      {
        field: "company_name",
        headerName: "COMPANY_NAME",
        width: 100,
        editable: false,
      },
      {
        field: "street",
        headerName: "STREET",
        width: 100,
        editable: false,
      },
      {
        field: "state",
        headerName: "STATE",
        width: 100,
        editable: false,
      },
      {
        field: "post_code",
        headerName: "POST_CODE",
        width: 100,
        editable: false,
      },
      {
        field: "email",
        headerName: "EMAIL",
        width: 160,
        editable: false,
      },
      {
        field: "phoneno",
        headerName: "PHONE_NO",
        width: 100,
        editable: false,
      },
      {
        field: "plateform",
        headerName: "PLATFORM",
        width: 160,
        editable: false,
      },
      {
        field: "category",
        headerName: "CATEGORY",
        width: 100,
        editable: false,
      },
      {
        field: "source",
        headerName: "SOURCE",
        width: 100,
        editable: false,
      },
      {
        field: "action",
        headerName: "ACTION",
        width: 100,
        editable: false,
      },
      {
        field: "entered_by",
        headerName: "ENTERED_BY",
        width: 100,
        editable: false,
      },
      {
        field: "comment",
        headerName: "COMMENT",
        width: 100,
        editable: false,
      }
    //   {
    //     field: 'delete',
    //     headerName: 'Delete',
    //     width: 70,
    //     renderCell: (rows) => {
    //       return (
    //         <IconButton onClick={()=>handleDelete(rows.contact_ID)}>
    //           <EditIcon />
    //         </IconButton>
    //       )   
    //     },
    //  },
      // {
      //   field: "delete",
      //   headerName: "Delete",
      //   width: 100,
      //   editable: false,
      // },
      // 
      
      // 
      
    ];

    // const handleDelete = (id) =>{
    //   // console.log(id);
    //   // id = state.id
    //   ContactServices.deleteById(id)
    //   .then(response =>{
    //     console.log("Deleted Successfully",response);
    //     // navigate("/AdminAllRecs");
    //   })
    //   .catch((err)=>{
    //     console.log("Something went Wrong",err);
    //   })
    // }
    
    const [rows,setRows] = useState([])
    
        useEffect(()=>{
        
          ContactServices.getAll()
            .then(res =>{
              console.log(res.data);
              setRows(res.data)
            },[])
            .catch(error=>{
              console.log("Something Went Wrong", error);
            },[]) 
      
          // Add square bracket here befre production
        },[])
        

    // const rows = [
        
    //     { id: 1, last_name: 'Snow', first_name: 'Jon', company_name: 35, street:'St', post_code:676,state:"stsatse",email:'gmail',phone_no:787897,source:'src',category:"dwhdu",plateform:"plateform",action:"whatsapp",comment:"cmt"},
    //     // { id: 2, last_name: 'Lannister', first_name: 'Cersei', company_name: 42 },
    //     // { id: 3, last_name: 'Lannister', first_name: 'Jaime', company_name: 45 },
    //     // { id: 4, last_name: 'Stark', first_name: 'Arya', company_name: 16 },
    //     // { id: 5, last_name: 'Targaryen', first_name: 'Daenerys', company_name: null },
    //     // { id: 6, last_name: 'Melisandre', first_name: null, company_name: 150 },
    //     // { id: 7, last_name: 'Clifford', first_name: 'Ferrara', company_name: 44 },
    //     // { id: 8, last_name: 'Frances', first_name: 'Rossini', company_name: 36 },
    //     // { id: 9, last_name: 'Roxie', first_name: 'Harvey', company_name: 65 },
    // ];
    
  



  return (
    <>
    <AppBarrr />
    <Box mt={10}  sx={{ height: 400, width: "100%"  }}>
      <DataGrid
      style={{height:"500px"}}
        options={{
          paging: false
      }}
      //  components={{ Toolbar: GridToolbar }} 
      getRowId={(row) => row.contact_ID}
        rows={rows}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        // pcompany_nameSize={5}
        // rowsPerPcompany_nameOptions={[5]}
        // checkboxSelection
        // disableSelectionOnClick
        // experimentalFeatures={{ newEditingApi: true }}
        rowsPerPageOptions={[10,2,2]}
        componentsProps={{ toolbar: GridToolbar }}
        density="compact"
        
        />
    </Box>
        </>
  );
};
